<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="title"
      >
        Permissions Available
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="6"
      >
        <v-select
          v-model="category"
          :items="categories"
          item-text="title"
          item-value="value"
          label="Category"
          placeholder="Select category"
          clearable
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6">
        <div class="d-inline-flex justify-start align-center" v-show="category !== null">
          <v-btn color="success me-5" @click="selectAll">Select All</v-btn>
          <v-btn color="secondary" @click="unselectAll">Unselect All</v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3" v-for="(permission, index) in filteredPermisssions" :key="permission.id">
        <v-checkbox v-model="selectedPermissions" :id="permission.id?.toString()" :value="permission.id.toString()" :label="t(permission.name_translation)" />
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">

        </v-col>
    </v-row>
    <v-row v-show="selectedPermissions.length > 0">
      <v-col cols="12" class="title">
        Permissions Selected
      </v-col>

      <v-col cols="12" class="d-inline-flex flex-wrap">
        <v-chip class="mr-2 mb-2" v-for="permission in selectedPermissions">
          {{ t(getNameTranslationById(permission)) }}
        </v-chip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { t } from '@/plugins/i18n'
import { ref, onMounted, computed } from '@vue/composition-api'
import useUserPermission from '../../composables/userPermission'

export default {
  model: {
    prop: 'permissions',
    event: 'update',
  },
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { permissions, getNameTranslationById } = useUserPermission()

    const category = ref(null)
    const selectedPermissions = computed({
      get: () => props.permissions,
      set: value => {
        emit('update', value)
      },
    })

    const filteredPermisssions = computed(() => {
      if (category.value === null) return []

      return permissions.value?.filter(permission => permission.category === category.value)
    })

    const categories = computed(() => {
      return permissions.value
        ?.map(permission => ({
          title: humanizeName(permission.category),
          value: permission.category,
        }))
        .filter((category, index, self) => self.findIndex(t => t.value === category.value) === index)
    })

    const selectAll = () => {
      selectedPermissions.value = [...selectedPermissions.value, ...filteredPermisssions.value.map(permission => permission.id.toString())].filter(
        (permission, index, self) => self.indexOf(permission) === index,
      )
    }

    const unselectAll = () => {
      selectedPermissions.value = selectedPermissions.value.filter(
        permission => !filteredPermisssions.value.map(permission => permission.id.toString()).includes(permission),
      )
    }

    const humanizeName = category => {
      return category
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    }

    return {
      t,

      category,
      categories,

      getNameTranslationById,

      selectedPermissions,
      filteredPermisssions,

      selectAll,
      unselectAll,
    }
  },
}
</script>

