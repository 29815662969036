var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    ref: "formRef",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-sm-h5"
  }, [_vm._t("title", function () {
    return [_vm._v(" Create Admin Role ")];
  })], 2), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('translation-locales')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Key",
      "rules": [_vm.validators.required],
      "placeholder": "e.g. hr_manager",
      "outlined": ""
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('translatable-input', {
    attrs: {
      "label": "Name"
    },
    model: {
      value: _vm.form.name_translation,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name_translation", $$v);
      },
      expression: "form.name_translation"
    }
  })], 1)], 1), _c('permission-selection', {
    model: {
      value: _vm.form.permissions,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "permissions", $$v);
      },
      expression: "form.permissions"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "error"
    },
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v(" Close ")]), _c('v-btn', {
    attrs: {
      "type": "submit",
      "color": "success",
      "loading": _vm.loading,
      "disabled": _vm.loading
    }
  }, [_vm._v(" Confirm ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }