<template>
  <v-dialog
      v-model="isDialogVisible"
      max-width="800"
    >

    <!-- Dialog Activator -->
      <template #activator="{ on, attrs }">
        <v-btn color="success" outlined class="mb-1 me-3" v-bind="attrs" v-on="on">
          <v-icon size="17" class="me-1">
            {{ icons.mdiAccountPlus }}
          </v-icon>
          <span>Create</span>
        </v-btn>
      </template>

    <!-- Dialog Content -->
    <role-form ref="formRef" @close="onClose" @submit="onSubmit" />
  </v-dialog>
</template>

<script>
import { useNotifySuccess } from '@/composables'
import { mdiAccountPlus, mdiRefresh } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { roleService } from '../../services'
import RoleForm from './RoleForm.vue'

export default {
  components: {
    RoleForm,
  },
  setup(_, { emit }) {
    const formRef = ref(null)
    const isDialogVisible = ref(false)

    const onClose = () => {
      isDialogVisible.value = false
    }

    const onSubmit = async data => {
      try {
        await roleService.storeRole(data)

        isDialogVisible.value = false

        formRef.value.resetForm()

        emit('created')

        useNotifySuccess({
          content: 'Admin user has been created successfully.',
        })
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    return {
      formRef,
      isDialogVisible,

      onClose,
      onSubmit,

      icons: {
        mdiAccountPlus,
        mdiRefresh,
      },
    }
  },
}
</script>
