<template>
  <v-card>
      <v-data-table
        :headers="columns"
        :items="items"
        :options.sync="options"
        :server-items-length="itemsTotal"
        :loading="loading"
        :header-props="headerprops"
        class="table-section text-no-wrap"
      >
        <!-- ID -->
        <template #[`item.id`]="{ item }">
          {{ item.id }}
        </template>

        <!-- key -->
        <template #[`item.name`]="{ item }">
          {{ item.name }}
        </template>

        <!-- Name -->
        <template #[`item.name_translation`]="{ item }">
          {{ t(item.name_translation) }}
        </template>

        <!-- Permissions -->
        <template #[`item.permissions`]="{ item }">

          <v-chip class="v-chip-light-bg" small>
            {{ item.name === 'admin' ? "All" : item.permissions.length }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <div class="d-flex align-center justify-center">
            <!-- dropdown -->
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon size="18">
                    {{ icons.mdiDotsVertical }}
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  link
                >
                  <v-list-item-title>
                    <edit-dialog :role="item" @updated="onUserUpdated" />
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-data-table>
  </v-card>
</template>

<script>
import { useOldDataTable } from '@/composables'
import { t } from '@/plugins/i18n'
import { mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync } from '@mdi/js'
import EditDialog from './EditDialog.vue'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemsTotal: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EditDialog,
  },
  setup(props, { emit }) {
    const { options, headerprops, resetPage, optionWatcher } = useOldDataTable()

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'Key', value: 'name', sortable: false },
      { text: 'Name', value: 'name_translation', sortable: false },
      { text: 'Permissions Granted', value: 'permissions', sortable: false },
      {
        text: 'ACTIONS',
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ]

    const onUserUpdated = () => {
      emit('updated')
    }

    optionWatcher(emit)

    return {
      t,
      dayjs,

      // Table config
      columns,
      options,
      headerprops,

      onUserUpdated,

      resetPage,

      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>