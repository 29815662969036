import store from '@/store'
import { computed } from '@vue/composition-api'

export default function useUserPermission() {
  const fetchPermissions = () =>
    store.dispatch('admin/fetchPermissions', {
      paginate: 0,
    })

  const permissions = computed(() => store.getters['admin/getPermissions'])

  if (!store.getters['admin/getPermissions'].length) {
    fetchPermissions()
  }

  const getPermissionIdByName = name => {
    const permission = store.getters['admin/getPermissions'].find(permission => permission.name === name)

    return permission ? permission.id : ''
  }

  const getPermissionNameById = permissionId => {
    const permission = store.getters['admin/getPermissions'].find(permission => permission.id === parseInt(permissionId))

    return permission?.name
  }

  const getNameTranslationByName = permissionName => {
    const permission = store.getters['admin/getPermissions'].find(permission => permission.name === permissionName)

    return permission ? permission.name_translation : ''
  }

  const getNameTranslationById = id => {
    const permission = store.getters['admin/getPermissions'].find(permission => permission.id === parseInt(id))

    return permission?.name_translation
  }

  const getCategories = () => permissions.value?.map(permission => permission.category).filter((value, index, self) => self.indexOf(value) === index)

  return {
    permissions,
    fetchPermissions,
    getPermissionIdByName,
    getPermissionNameById,
    getNameTranslationByName,
    getNameTranslationById,
    getCategories,
  }
}
