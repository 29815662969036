<template>
  <div>
    <role-filter
      v-model="filters"
      class="mb-3"
      @updated="onFilterUpdated"
    />

    <v-card>
      <!-- Buttons -->
      <v-card-text class="d-flex justify-space-between">
        <v-btn
          color="primary"
          :loading="loading"
          :disabled="loading"
          @click="fetchData"
        >
          <v-icon class="prefix-icon">
            {{ icons.mdiRefresh }}
          </v-icon>
          <span>
            {{ $t('common.list.refresh') }}
          </span>
        </v-btn>

        <create-dialog @created="fetchData" />
      </v-card-text>

      <!-- Table -->
      <role-table
        ref="tableRef"
        :loading="loading"
        :items="items"
        :items-total="itemsTotal"
        @updated="fetchData"
        @options-updated="onOptionsUpdated"
      />
    </v-card>
  </div>
</template>

<script>
import {
mdiDatabaseExportOutline, mdiDeleteOutline, mdiDotsVertical, mdiMenuDown, mdiMenuUp, mdiPencilOutline, mdiPlus, mdiRefresh, mdiSync,
} from '@mdi/js'

import { useOldDataFetch, useOldTableList } from '@/composables'
import { t } from '@/plugins/i18n'
import { ref } from '@vue/composition-api'
import CreateDialog from '../components/role/CreateDialog.vue'
import RoleFilter from '../components/role/RoleFilter.vue'
import RoleTable from '../components/role/RoleTable.vue'
import { roleService } from '../services'

export default {
  components: {
    RoleFilter,
    RoleTable,
    CreateDialog,
  },
  setup() {
    const { parseParam } = useOldDataFetch()
    const {
      loading, tableRef, items, itemsTotal, mergeOptionToParams,
    } = useOldTableList()

    const filters = ref({
      name: null,
    })

    const params = ref({
      ...filters.value,
    })

    const fetchData = async () => {
      loading.value = true

      try {
        const { records, pagination } = await roleService.getRoles(parseParam(params.value))

        items.value = records
        itemsTotal.value = pagination.total
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const onFilterUpdated = value => {
      params.value = {
        ...params.value,
        ...value,
        page: 1,
      }

      tableRef.value.resetPage(1)

      fetchData()
    }

    const onOptionsUpdated = options => {
      params.value = mergeOptionToParams(options, params.value)

      fetchData()
    }

    return {
      // Dependecies
      t,

      // Variables
      loading,

      tableRef,
      filters,
      items,
      itemsTotal,

      // functions
      fetchData,
      onFilterUpdated,
      onOptionsUpdated,

      // Icons
      icons: {
        mdiDotsVertical,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiMenuDown,
        mdiMenuUp,
        mdiRefresh,
        mdiPlus,
        mdiDatabaseExportOutline,
        mdiSync,
      },
    }
  },
}
</script>
