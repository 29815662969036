<template>
  <v-card class="filter-section">
      <v-row>
        <v-col cols="6">
          <v-card-title class="font-weight-bold ">
            {{ $t('common.list.filters') }}
          </v-card-title>
        </v-col>
        <v-col
          cols="6"
          class="d-flex justify-end"
        >
          <v-btn
            icon
            class="mr-3"
            @click="showFilter = !showFilter"
          >
            <v-icon>{{ showFilter ? icons.mdiMenuUp: icons.mdiMenuDown }}</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row
          v-show="showFilter"
          class="px-2 ma-0"
        >
          <!-- role filter -->

          <v-col
            cols="12"
            sm="3"
          >
            <v-text-field
              v-model="search"
              single-line
              dense
              outlined
              hide-details
              clearable
              :placeholder="$t('common.list.searchByName')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card>
</template>


<script>
import { t } from '@/plugins/i18n'
import { computed, ref, watch } from '@vue/composition-api'
import { mdiMenuDown, mdiMenuUp } from '@mdi/js'
import RoleFilter from '../filter/user/RoleFilter.vue'
import StatusFilter from '../filter/user/StatusFilter.vue'
import { debounce } from 'lodash'

export default {
  model: {
    prop: 'filters',
    event: 'updated',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  components: {
    RoleFilter,
    StatusFilter,
  },
  setup(props, { emit }) {
    const showFilter = ref(true)

    const search = computed({
      get: () => props.filters.search,
      set: debounce(value => {
        emit('updated', {
          ...props.filters,
          search: value,
        })
      }, 500),
    })

    const status = computed({
      get: () => props.filters.active,
      set: value => {
        emit('updated', {
          ...props.filters,
          active: value,
        })
      },
    })

    const roleId = computed({
      get: () => props.filters.role_id,
      set: value => {
        emit('updated', {
          ...props.filters,
          role_id: value,
        })
      },
    })

    return {
      t,

      showFilter,

      search,

      icons: {
        mdiMenuDown,
        mdiMenuUp,
      },
    }
  },
}
</script>
